import { forwardRef, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { format, getYear, getMonth, addDays } from "date-fns";
import { range } from "lodash";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
const ButtonInput = forwardRef(
  (
    { value, onClick, defaultValue = null, placeholderText, dateFormat },
    ref
  ) => (
    <button
      onClick={onClick}
      ref={ref}
      type="button"
      className="form-control text-left"
    >
      {defaultValue != null && placeholderText != null ? (
        <>
          <div className="text-gray-500">{placeholderText}</div>
        </>
      ) : (
        <>
          {value ? (
            format(new Date(value), dateFormat)
          ) : (
            <>
              {defaultValue != null ? (
                format(new Date(defaultValue), "MMMM dd, yyyy")
              ) : (
                <div className="text-gray-500">
                  {placeholderText ? placeholderText : "Select a date"}
                </div>
              )}
            </>
          )}
        </>
      )}
    </button>
  )
);

const KNDatePicker = (props) => {
  const [startDate, setDate] = useState(null);
  const years = range(1900, getYear(new Date()) + 6, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div>
      <DatePicker
        placeholderText={
          props.placeholderText ? props.placeholderText : "Select a date"
        }
        selected={props.startDate || startDate}
        isClearable={props.isClearable ? props.isClearable : false}
        utcOffset={0}
        inline={props.inline ? props.inline : false}
        onChange={(date) => {
          if (date) {
            date.setHours((-1 * date.getTimezoneOffset()) / 60);
          }
          console.log(`Selected Date: ${date}`);
          setDate(date);
          props.setDate(date);
        }}
        includeDates={
          props.includeDates
            ? props.includeDates.map((i) => {
                return addDays(new Date(i), 0);
              })
            : undefined
        }
        showMonthYearPicker={
          props.showMonthYearPicker ? props.showMonthYearPicker : false
        }
        showFullMonthYearPicker={
          props.showMonthYearPicker ? props.showMonthYearPicker : false
        }
        disabled={props.disabled ? props.disabled : false}
        customInput={
          <ButtonInput
            defaultValue={props.defaultValue}
            placeholderText={
              props.defaultValue == null ? props.placeholderText : null
            }
            dateFormat={
              props.showMonthYearPicker ? "MMMM, yyyy" : "MMMM dd, yyyy"
            }
          />
        }
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <>
            <div className="flex items-center justify-between pb-2">
              <span className="font-semibold text-gray-700 mb-1">
                {format(date, "MMMM yyyy")}
              </span>

              <div className="space-x-2">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  type="button"
                  className={`${
                    prevMonthButtonDisabled && "cursor-not-allowed opacity-50"
                  } inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500`}
                >
                  <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                </button>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  type="button"
                  className={`${
                    nextMonthButtonDisabled && "cursor-not-allowed opacity-50"
                  } inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500`}
                >
                  <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                </button>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="flex-auto">
                <select
                  className="form-control form-control-sm"
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-auto">
                <select
                  className="form-control form-control-sm"
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

KNDatePicker.propTypes = {
  defaultValue: PropTypes.any,
  placeholderText: PropTypes.string,
  setDate: PropTypes.func.isRequired,
};

export default KNDatePicker;
